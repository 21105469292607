import Link from 'next/link'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { route } from '~generated/routes'
import Label from '~shared/components/Form/Label'
import Input from '~shared/components/Input/Input'

import Checkbox from '@/mc-onboarding/components/Checkbox'
import { LoginForm } from '@/users/validation/login'

type Props = {
  form: UseFormMethods<LoginForm>
  onSubmit: (data: LoginForm) => Promise<void>
}

const LoginFormComponent = ({ form, onSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className='flex w-full flex-col gap-3'
    >
      <Input
        ref={form.register}
        name='email'
        type='email'
        autoFocus
        label={t('login.email')}
        autoComplete='off'
        error={form.errors.email}
      />
      <Input
        ref={form.register}
        name='password'
        type='password'
        label={t('login.password')}
        autoComplete='current-password'
        error={form.errors.password}
      />
      <div className='flex flex-row items-center justify-between gap-3'>
        <Controller
          control={form.control}
          name='rememberMe'
          defaultValue={false}
          render={({ value, onChange }) => (
            <div className='flex flex-row items-center gap-2'>
              <Checkbox
                checked={value}
                label='Remember me'
                id='rememberMe'
                onCheck={onChange}
              />
              <Label htmlFor='rememberMe' className='font-bold'>
                Remember me
              </Label>
            </div>
          )}
        />
        <button
          tabIndex={-1}
          data-testid='__hidden-login'
          type='submit'
          className='hidden'
        />
        <Link href={route('/forgot-password')}>
          <a
            data-testid='login-forgot-password-button'
            className='inline-block bg-gradient-to-r from-purple to-[#b744ca] bg-clip-text text-sm text-transparent'
          >
            {t('login.forgot_password')}
          </a>
        </Link>
      </div>
    </form>
  )
}

export default LoginFormComponent
