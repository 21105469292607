import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import { FieldError } from 'react-hook-form'

import Error from '../Form/Error'
import Label from '../Form/Label'
import Loading from '../Loading/Loading'

import styles from './Input.module.scss'

export interface InputProps {
  error?: FieldError | string
  errorClassName?: string
  inputClassName?: string
  errorBorder?: boolean
  label?: React.ReactNode
  icon?: React.ReactNode
  iconPos?: 'right'
  loading?: boolean
  testId?: string
  fieldStatus?: 'required' | 'optional'
}

const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & JSX.IntrinsicElements['input']
>(
  (
    {
      autoComplete = 'chrome-off',
      icon,
      iconPos = 'right',
      className,
      inputClassName,
      error,
      errorClassName,
      label,
      loading,
      errorBorder,
      testId: rawTestId,
      fieldStatus,
      ...props
    },
    ref,
  ) => {
    const { testId, errorTestId } = React.useMemo(() => {
      const raw = rawTestId || props.id || undefined
      if (!raw) return { testId: undefined, errorTestId: undefined }
      return { testId: kebabCase(raw), errorTestId: `${kebabCase(raw)}-error` }
    }, [props.id, rawTestId])

    return (
      <div className={cx('relative flex flex-col items-start', className)}>
        <Label testId={testId} htmlFor={props.id} hasError={Boolean(error)}>
          {label}
          {fieldStatus === 'optional' ? (
            <span className='ml-2 text-xs font-normal italic text-black/25'>
              (optional)
            </span>
          ) : fieldStatus === 'required' ? (
            <span className='ml-0.5 text-red'>*</span>
          ) : null}
        </Label>
        <div className='relative w-full'>
          <input
            {...props}
            data-testid={testId}
            autoComplete={autoComplete}
            className={cx(
              'my-1 w-full rounded-lg border-2 px-2 py-2 text-base font-light text-black placeholder-black/50 outline-none focus:text-purple-900',
              styles.input,
              props.disabled ? 'bg-grey-dark' : 'bg-white',
              errorBorder
                ? 'border-red focus:border-red-500'
                : 'border-grey-dark focus:border-purple-300',
              inputClassName,
              icon && iconPos === 'right' && 'pr-10',
            )}
            ref={ref}
          />
          {icon && iconPos === 'right' ? (
            <div className='pointer-events-none absolute inset-y-0 right-3 flex select-none items-center justify-center text-grey-darker'>
              {icon}
            </div>
          ) : null}
          {loading ? (
            <div className='absolute inset-y-0 right-3 flex items-center justify-center'>
              <Loading colour='grey-dark' variant='small' />
            </div>
          ) : null}
        </div>
        <Error testId={errorTestId} className={errorClassName ?? ''}>
          {typeof error === 'string' ? error : error?.message}
        </Error>
      </div>
    )
  },
)

Input.displayName = 'TextInput'

export default Input
