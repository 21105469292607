import {
  getRedirectRouteFromQueryParams,
  showToastNotification,
  timeOfDay,
} from '@wise/utils'
import { motion } from 'framer-motion'
import Router from 'next/router'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import EnvelopeSVG from '~shared/components/Icons/svg/envelope.svg'
import Loading from '~shared/components/Loading/Loading'
import LoginFormComponent from '~shared/components/LoginForm/LoginForm'
import GoogleSVG from '~shared/components/Svgs/google.svg'
import PageHeader from '~shared/components/UI/PageHeader'
import WithWiseLogo from '~shared/components/WithWiseLogo/WithWiseLogo'
import { useMode } from '~shared/hooks/useMode'
import { TRANSLATIONS_ENABLED, locales } from '~shared/i18n/i18n'
import { trackAnalyticsEvent } from '~shared/services/analytics/analytics'
import { useApollo } from '~shared/services/apollo/apollo'
import { Authenticator } from '~shared/services/firebase/auth/Authenticator'
import { log } from '~shared/utils/log'

import FailedSSONotification from '@/single-sign-on/components/FailedSSONotification'
import { SSOProvider } from '@/single-sign-on/utils/provider'
import { LoginForm, loginSchemaResolver } from '@/users/validation/login'

import { AppPage } from '../types'

type LoginState =
  | { type: 'idle' }
  | { type: 'loading'; source: 'sso' | 'password' }

const LoginPage: AppPage = () => {
  const { t, i18n } = useTranslation()
  const apolloClient = useApollo()
  const mode = useMode()

  const [status, setStatus] = React.useState<LoginState>({ type: 'idle' })
  const [showLoginForm, setShowLoginForm] = React.useState(false)

  const form = useForm<LoginForm>({
    resolver: loginSchemaResolver,
  })

  React.useEffect(() => {
    log('apolloClient', 'Clearing Apollo Cache...')
    apolloClient.cache.reset()
  }, [apolloClient])

  const onLogin = React.useCallback(
    async (data: LoginForm) => {
      setStatus({ type: 'loading', source: 'password' })
      try {
        const result = await Authenticator.signIn(
          data.email,
          data.password,
          data.rememberMe,
        )
        if (!result) throw new Error('Could not login. Please try again later.')
        trackAnalyticsEvent('user_logged_in', {
          mode,
          firebase_id: result.id,
        })
        const url = getRedirectRouteFromQueryParams(Router.query) ?? '/'
        Router.push(url)
      } catch (error) {
        form.setError('email', {
          type: 'server',
          message: error instanceof Error ? error.message : 'Unknown error',
        })
        setStatus({ type: 'idle' })
      }
    },
    [form, mode],
  )

  const onSSO = React.useCallback(
    async (provider: SSOProvider) => {
      setStatus({ type: 'loading', source: 'sso' })
      try {
        const result = await Authenticator.signInWith(provider)
        if (!result) throw new Error(t('login.sso_not_supported_description'))
        const url = getRedirectRouteFromQueryParams(Router.query) ?? '/'
        Router.push(url)
      } catch (error) {
        await Authenticator.logout()
        const message = error instanceof Error ? error.message : 'Unknown error'
        showToastNotification({
          type: 'custom',
          description: (
            <FailedSSONotification
              message={message}
              title={t('login.sso_not_supported_title')}
            />
          ),
          position: 'top-right',
        })
        setStatus({ type: 'idle' })
      }
    },
    [t],
  )

  return (
    <div className='flex min-h-screen w-full flex-col items-stretch bg-gradient-to-t from-grey-300 to-white lg:flex-row'>
      <div className='relative flex flex-1 flex-shrink-0 flex-col bg-gradient-to-r from-purple to-[#b744ca] lg:rounded-r-xl'>
        <div className='flex h-32 items-center justify-center px-16 lg:justify-start'>
          <WithWiseLogo className='w-32' />
        </div>
        <div className='flex flex-1 flex-col items-center justify-center px-4 md:px-8 xl:px-16'>
          <div className='relative w-full min-w-200 max-w-600 rounded-md bg-white p-8 shadow-lg'>
            <PageHeader className='pb-1' textClassName='text-3xl'>
              {t(timeOfDay())}
            </PageHeader>
            <p
              className='mb-6 font-light'
              onClick={() => setShowLoginForm(!showLoginForm)}
            >
              {t(
                showLoginForm
                  ? 'login.provide_message'
                  : 'login.provide_login_method',
              )}
            </p>
            <div>
              <motion.div
                aria-hidden={!showLoginForm}
                className='-mx-1 overflow-hidden px-1' // Allows the outlines of the inputs to be visible
                initial={false}
                animate={{
                  transition: { type: 'tween' },
                  opacity: showLoginForm ? 1 : 0,
                  height: showLoginForm ? 'auto' : 0,
                }}
              >
                <div className='mb-4'>
                  {showLoginForm ? (
                    <LoginFormComponent form={form} onSubmit={onLogin} />
                  ) : null}
                </div>
              </motion.div>
              <button
                data-testid='login'
                className={twMerge(
                  'mb-3 flex w-full flex-row items-center justify-center rounded-md border border-black py-2.5 transition-all duration-500',
                  showLoginForm
                    ? 'bg-black font-bold text-white'
                    : 'bg-white text-black hover:bg-black/5',
                )}
                onClick={() => {
                  if (showLoginForm) return form.handleSubmit(onLogin)()
                  setShowLoginForm(true)
                }}
                type='button'
              >
                {showLoginForm ? (
                  t('login.login')
                ) : (
                  <>
                    <EnvelopeSVG className='mr-2 inline-block text-xl' />{' '}
                    {t('login.email_and_password')}
                  </>
                )}
              </button>
              <motion.div
                aria-hidden={!showLoginForm}
                initial={false}
                className='overflow-hidden'
                animate={{
                  transition: { type: 'tween' },
                  opacity: showLoginForm ? 1 : 0,
                  height: showLoginForm ? 'auto' : 0,
                }}
              >
                <div className='pb-3'>
                  <p className='py-2 text-center font-light tracking-normal'>
                    {t('login.or_continue_with')}
                  </p>
                </div>
              </motion.div>
              <div className='flex flex-col gap-3'>
                <button
                  data-testid='login'
                  className='flex w-full items-center justify-center rounded-md border border-black bg-white py-2.5 text-black hover:bg-black/5'
                  type='button'
                  onClick={() => onSSO(SSOProvider.Google)}
                >
                  <GoogleSVG className='mr-2 inline-block text-xl' />
                  {t('login.google')}
                </button>
              </div>
            </div>
            {status.type === 'loading' ? (
              <div className='absolute inset-1 flex flex-col items-center justify-center gap-6 bg-white/80 backdrop-blur-sm backdrop-grayscale'>
                <Loading variant='default' colour='purple-dark' />
                <p className='text-sm font-bold text-purple-dark'>
                  {t('login.loading')}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <div className='flex h-32 flex-row items-end'>
          {TRANSLATIONS_ENABLED ? (
            <ul className='flex w-full items-center justify-start gap-x-1 p-4'>
              {locales.map((locale) => (
                <li key={locale.code}>
                  <button
                    data-testid='change-language-button'
                    className={twMerge(
                      'rounded-lg px-2 py-0.5',
                      i18n.resolvedLanguage === locale.code
                        ? 'bg-purple-light/20 font-black text-purple-light'
                        : 'font-bold text-purple-light/40 hover:bg-purple-light/20',
                    )}
                    type='button'
                    onClick={() => i18n.changeLanguage(locale.code)}
                  >
                    {locale.code.toUpperCase()}
                  </button>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      <div className='flex flex-1 flex-shrink-0 flex-col px-4'>
        <div className='h-32' />
        <div className='mb-16 flex flex-1 flex-col items-center justify-center lg:mb-0'>
          <div className='flex w-full min-w-200 max-w-500 flex-col items-center gap-1 rounded-md bg-white p-8 shadow-sm'>
            <PageHeader textClassName='text-3xl from-black to-black'>
              {t('login.welcome')}
            </PageHeader>
            <p className='text-center font-light'>{t('login.message_one')}</p>
          </div>
        </div>
        <div className='flex h-32 flex-col items-center justify-start gap-3 text-sm'>
          <p className='max-w-500 text-center font-light tracking-normal text-black/50'>
            {t('login.message_two')}
          </p>
          <p className='max-w-500 text-center font-light tracking-normal text-black/50'>
            {t('login.message_three')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(LoginPage)
