import { kebabCase } from 'lodash'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  children: React.ReactNode
  className?: string
  testId?: string
  textClassName?: string
}

const PageHeader = ({ children, className, testId, textClassName }: Props) => {
  return (
    <div className={twMerge('pb-2 font-bold', className)}>
      <h1
        className={twMerge(
          'inline-block bg-gradient-to-r from-theme to-theme-light bg-clip-text text-4xl tracking-tight text-transparent',
          textClassName,
        )}
        data-testid={`${kebabCase(testId)}-page-header-title`}
      >
        {children}
        {typeof children === 'string' && !children.endsWith('.') ? '.' : ''}
      </h1>
    </div>
  )
}

export default PageHeader
