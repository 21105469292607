import { AnimatePresence, motion } from 'framer-motion'
import { kebabCase } from 'lodash'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  testId?: string
  className?: string
}

const Error: React.FC<Props> = ({ children, testId, className }) => {
  const wrapped = React.useMemo(() => {
    if (typeof children === 'string')
      return <p data-testid={kebabCase(testId)}>{children}</p>
    return <div data-testid={kebabCase(testId)}>{children}</div>
  }, [children, testId])

  return (
    <AnimatePresence initial={false}>
      {children ? (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className={twMerge(
            'relative text-ellipsis whitespace-pre-wrap text-sm font-light text-red',
            className,
          )}
          transition={{ duration: 0.1 }}
        >
          {wrapped}
          <div
            data-error-scroll-anchor
            className='invisible absolute top-0 left-0'
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default Error
