import cx from 'classnames'
import { motion } from 'framer-motion'

import Error from '~shared/components/Form/Error'
import TickIcon from '~shared/components/Icons/svg/tick.svg'

interface Props {
  children?: React.ReactNode
  label: string
  error?: string
  onCheck: (newValue: boolean) => void
  checked: boolean
  testId?: string
  id?: string
  disabled?: boolean
  isOnboarding?: boolean
}

const Checkbox = ({
  children,
  error,
  label,
  onCheck,
  checked,
  testId,
  id,
  disabled,
  isOnboarding,
}: Props) => {
  return (
    <>
      <button
        data-testid={testId}
        role='checkbox'
        disabled={disabled}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-checked={checked ? 'true' : 'false'}
        aria-label={label}
        className='-m-1 flex min-h-[44px] cursor-pointer flex-row items-center gap-3 p-1 text-left disabled:cursor-default'
        onClick={() => onCheck(!checked)}
        id={id}
        type='button'
      >
        <div
          className={cx(
            'h-7 w-7 flex-shrink-0 rounded-md border p-1 transition-all',
            isOnboarding
              ? checked
                ? 'scale-100 border-black-200 bg-theme'
                : 'scale-95 bg-white'
              : checked
              ? 'scale-100 border-black-200 bg-theme'
              : 'scale-95 border-black bg-theme/10',
          )}
        >
          <motion.div
            className='flex flex-row items-center justify-center'
            initial={false}
            animate={{ opacity: checked ? 1 : 0, translateY: checked ? 0 : 2 }}
          >
            <TickIcon
              className={cx('text-white', isOnboarding ? 'bg-theme' : '')}
            />
          </motion.div>
        </div>
        <div className={cx(error ? 'text-red' : '')}>{children}</div>
      </button>
      <Error>{error}</Error>
    </>
  )
}

export default Checkbox
