import Icon from '~shared/components/Icons/svg/alert-circle-light.svg'

interface Props {
  title: string
  message: string
}

const FailedSSONotification = ({ title, message }: Props): JSX.Element => {
  return (
    <div className='flex w-full flex-row items-center gap-6 p-3'>
      <Icon className='h-14 w-14 text-red' />
      <div className='flex flex-col gap-2'>
        <h3 className='text-2xl font-bold text-red'>{title}</h3>
        <p className='max-w-300 text-sm'>{message}</p>
      </div>
    </div>
  )
}

export default FailedSSONotification
